@import './variables';
@import './mixins';

.chart-wrapper {
  margin: 1em 0;
  justify-content: space-evenly;
  align-content: stretch;
  align-items: stretch;
  flex-flow: row wrap;
  display: flex;

  .chart-block {
    width: 19%;
    cursor: pointer;
    border: 2px solid $grayLightest;
    justify-content: space-between;
    align-content: stretch;
    align-items: center;
    display: flex;
    transition: all 0.15s ease-out 0s;
    border-radius: 40px;

    > i {
      background: $grayLightest;
      width: 33%;
      color: $white;
      font-size: 2.5em;
      //line-height: 2em;
      margin: -2px 0 -2px -2px;
      padding: 0.33em 0 0.33em;
      @include inline-block();

      &:before {
        width: 100%;
        text-align: center;
        @include inline-block();
      }
    }

    .chart-overview {
      width: 66%;
      color: $gray;
      font-size: 1em;
      padding: 0 0.33em;
      margin: auto;
      @include inline-block();
      @include antialiased();

      small {
        display: block;
        color: $grayLight;
        font-size: 0.65em;
        margin-top: -0.25em;
        text-transform: uppercase;
      }

      a {
        font-size: 1rem;
        color: $grayLight;
        text-align: center;
        text-decoration: underline;
        @include inline-block();
      }
    }

    /*-----------  Block Types  -----------*/

    &.views {
      i {
        background-color: $gray;
      }

      &:hover {
        border-color: lighten($gray, 33%);
      }

      &.activated {
        border-color: $gray;
      }
    }

    &.contacts {
      i {
        background-color: $green;
      }

      &:hover {
        border-color: lighten($green, 33%);
      }

      &.activated {
        border-color: $green;
      }
    }

    &.clicks {
      i {
        background-color: $yellow;
      }

      &:hover {
        border-color: lighten($yellow, 33%);
      }

      &.activated {
        border-color: $yellow;
      }
    }

    &.social {
      i {
        background-color: $blue;
      }

      &:hover {
        border-color: lighten($blue, 33%);
      }

      &.activated {
        border-color: $blue;
      }
    }

    &.feedback {
      i {
        background-color: $purple;
      }

      &:hover {
        border-color: lighten($purple, 33%);
      }

      &.activated {
        border-color: $purple;
      }
    }

    &.call {
      i {
        background-color: $purple;
      }

      &:hover {
        border-color: lighten($purple, 33%);
      }

      &.activated {
        border-color: $purple;
      }
    }

    /*-----------  Block States  -----------*/

    &.activated {
      cursor: default;
    }

    &:not([data-chart]) {
      cursor: default;
      border-style: dashed;

      &:hover {
        border-color: lighten($grayDark, 33%);

        i {
          background: lighten($grayDark, 33%);
        }
        a {
          color: $blueLight;
        }
      }

      > i {
        line-height: 2em;
      }
    }
  }
}
