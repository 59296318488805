@import './assets/styles/override_material_style';
@import './assets/styles/_variables';

.App {
  font-family: 'Montserrat', serif;
  line-height: 1.3;
  min-width: 1440px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  color: #666666;
  font-weight: 400;
}

h1 {
  font-size: 2em;
  font-weight: 600;
  color: #e8562a;
}

p {
  margin-top: 0;
}

/*-----------  Images  -----------*/
img {
  max-width: 100%;
  height: auto;
  border: none;
  margin-left: auto;
  margin-right: auto;
  vertical-align: bottom;
}

/*-----------  Anchors (links)  -----------*/
a {
  cursor: pointer;
  text-decoration: none;
  transition: all 0.15s ease-out 0s;
}
a[href] {
  cursor: pointer;
}
a.active {
  cursor: default;
}
a[disabled] {
  cursor: not-allowed;
  pointer-events: none;
}
a.hidden {
  display: none;
  visibility: hidden;
}
a.small {
  color: #45b4d6;
  font-size: 0.8rem;
  text-decoration: underline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a.small:hover {
  color: #3cd4d9;
}
a .introjs-button {
  color: white !important;
  background: #27ab9e !important;
  text-shadow: none !important;
}

/*-----------  Headers  -----------*/
h1,
h2,
h3,
h4,
h5,
h6,
legend {
  margin: 0;
  color: $black;
  line-height: 100%;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1.text-color,
h2.text-color,
h3.text-color,
h4.text-color,
h5.text-color,
h6.text-color,
legend.text-color {
  color: #666666 !important;
  text-align: center;
  border-bottom: 1px solid lightgrey;
}

h1 {
  font-size: 2.5em;
  font-weight: 600;
}

h2 {
  font-size: 2em;
  text-transform: uppercase;
}

h3,
legend {
  font-size: 2.5em;
  text-transform: uppercase;
}

h4 {
  font-size: 1.75em;
  text-transform: uppercase;
}

h5 {
  font-size: 1.15em;
  margin-bottom: 1.25em;
  text-transform: uppercase;
}

h6 {
  font-size: 1em;
}

/*-----------  Paragraphs  -----------*/
p {
  margin-top: 0;
  font-weight: 400;
  line-height: 150%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p.text-color {
  text-align: center;
  font-size: 12px;
}

/*-----------  Lists  -----------*/
ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

/*-----------  Modifiers  -----------*/
.underlined {
  text-decoration: underline;
}

.strike {
  display: block;
  overflow: hidden;
  margin: 1em 0;
  text-align: center;
  white-space: nowrap;
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: black;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

/*-----------  Tables  -----------*/
.table-condensed tr th,
.table-condensed tr td {
  padding: 1px;
}

table {
  width: 100%;
  max-width: 100%;
  margin: 1em auto;
  border-collapse: collapse;
}

table tr th,
table tr td {
  color: $grayDark;
  text-align: left;
  padding: 1.05em 1.33em;
}

table tr th.color-flag,
table tr td.color-flag {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  width: 0.33em !important;
  min-width: 0.33em !important;
}

table tr th .refresh {
  color: #fff;
  background: #e8562a;
  border-radius: 50%;
  margin: 0px 38%;
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

table tr th {
  font-size: 0.85em;
  font-weight: normal;
}

table tr th.header {
  cursor: pointer;
  transition: all 0.15s ease-out 0s;
}

table tr th.header:after {
  opacity: 0;
  color: #e8562a;
  content: 'q';
  font-size: 0.5em;
  margin-left: 1.5em;
  font-family: 'hellobar';
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  transition: all 0.15s ease-out 0s;
}

table tr th.header.headerSortUp,
table tr th.header.headerSortDown {
  color: #e8562a;
}

table tr th.header.headerSortUp:after,
table tr th.header.headerSortDown:after {
  opacity: 1;
}

table tr th.header.headerSortUp:after {
  transform: rotate(-90deg);
}
table tr th.header.headerSortDown:after {
  transform: rotate(90deg);
}
table tr td {
  font-size: 0.9em;
  border-top: 1px solid $inputBorderColor;
  border-bottom: 1px solid $inputBorderColor;
}
table tr td.color-flag {
  background-color: #e8562a;
}
table tr td:first-of-type {
  border-left: 1px solid $inputBorderColor;
}
table tr td:last-of-type {
  border-right: 1px solid $inputBorderColor;
}
table tr td > p {
  margin: 0;
  font-size: 1.05em;
  color: #4f4f4f;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
table tr td > a {
  color: #e8562a;
}
table tr td > a:hover {
  color: #e04718;
}
table tr td .num {
  font-size: 1.5em;
  min-width: 5.5rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
table tr td .num small {
  display: block;
  color: #c6c6c6;
  font-size: 0.45em;
  margin-top: -0.25em;
  text-transform: uppercase;
}
table tr.see-more {
  border: none;
}
table tr.see-more td {
  border: none;
  position: relative;
  text-align: center;
}
table tr.see-more td:before {
  top: 50%;
  left: 0;
  content: '';
  width: 100%;
  position: absolute;
  border-top: 1px solid #f6f6f6;
}
table tr.see-more td > a {
  padding: 0 1em;
  color: #3cd4d9;
  position: relative;
  background-color: white;
  text-decoration: underline;
}

table tr.see-more td > a:hover {
  color: #29cdd2;
}
table tr.see-more td a:after {
  color: #919191;
  content: 'q';
  font-size: 0.5em;
  margin-left: 1.5em;
  font-family: 'hellobar';
  transform: rotate(270deg);
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
}
table tr.see-more td a.seeing-more:after {
  transform: rotate(90deg);
}
table tr.type-social .color-flag {
  background-color: #45b4d6;
}
table tr.type-traffic .color-flag {
  background-color: #ffbf1f;
}
table tr.type-email .color-flag {
  background-color: #54d388;
}

/*-----------  Labels & Fieldsets  -----------*/
label {
  display: block;
  font-size: 0.85em;
  line-height: 133%;
  color: #4f4f4f;
  transition: all 0.15s ease-out 0s;
}

label.disabled {
  color: #919191;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

input {
  font-weight: 400;
}

/*-----------  Application States  -----------*/
#hb-application .label,
#hb-application label {
  font-size: 1em;
  color: #a5a5a5;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*-----------  Text Inputs  -----------*/
/*-----------  Inline States  -----------*/
span > input,
span > textarea {
  display: inline;
  margin: 0 0.5em;
  vertical-align: middle;
}

/*-----------  Select Dropdowns  -----------*/
.select-wrapper,
.editor-wrapper .color-select .color-select-wrapper {
  color: #666666;
  position: relative;
  margin: 0.75em auto;
  background-color: white;
}
.select-wrapper select,
.editor-wrapper .color-select .color-select-wrapper select,
.select-wrapper .select-block,
.editor-wrapper .color-select .color-select-wrapper .select-block {
  cursor: pointer;
  color: #4f4f4f;
  padding-right: 2.67em;
  background-color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.select-wrapper select option,
.editor-wrapper .color-select .color-select-wrapper select option,
.select-wrapper .select-block option,
.editor-wrapper .color-select .color-select-wrapper .select-block option {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.select-wrapper label,
.editor-wrapper .color-select .color-select-wrapper label {
  position: relative;
}
.select-wrapper:before,
.editor-wrapper .color-select .color-select-wrapper:before {
  right: 4rem;
  line-height: 50%;
  content: 'b';
  color: #919191;
  bottom: 0.75em;
  font-size: 0.75em;
  position: absolute;
  pointer-events: none;
  font-family: 'hellobar';
  transition: all 0.15s ease-out 0s;
}
.select-wrapper:hover:before,
.editor-wrapper .color-select .color-select-wrapper:hover:before {
  color: #666666;
}

label + .select-wrapper,
.editor-wrapper .color-select label + .color-select-wrapper {
  margin-top: 0;
}

/*-----------  Error States  -----------*/
.has-error span > input select,
.has-error span > textarea select {
  color: #e8562a;
  border-color: #e8562a;
}

.has-error span > input:before,
.has-error span > textarea:before {
  color: #e8562a;
}

/*-----------  Inline States  -----------*/
span.select-wrapper,
.editor-wrapper .color-select span.color-select-wrapper {
  margin: 0 0.5em !important;
}
span.select-wrapper:before,
.editor-wrapper .color-select span.color-select-wrapper:before {
  line-height: 350%;
}
span.select-wrapper select,
.editor-wrapper .color-select span.color-select-wrapper select {
  width: inherit;
  display: inline !important;
  vertical-align: middle;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select {
    padding-right: 18px;
  }
}

/*-----------  Application States  -----------*/
#hb-application span.select-wrapper,
#hb-application .editor-wrapper .color-select span.color-select-wrapper,
.editor-wrapper .color-select #hb-application span.color-select-wrapper {
  margin: 0 0.5em !important;
}
#hb-application span.select-wrapper:before,
#hb-application .editor-wrapper .color-select span.color-select-wrapper:before,
.editor-wrapper .color-select #hb-application span.color-select-wrapper:before {
  bottom: 0;
  line-height: 150%;
}

/*-----------  Radio & Checkbox  -----------*/
input[type='radio'],
input[type='checkbox'] {
  width: 5%;
  font-size: 1.5em;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
}

input[type='radio'] + label,
input[type='checkbox'] + label {
  margin: 0;
  width: 95%;
  color: #919191;
  cursor: pointer;
  text-align: left;
  position: relative;
  padding-left: 1em;
  font-size: 0.85em;
  font-weight: 600;
  user-select: none;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  transition: all 0.15s ease-out 0s;
}
input[type='radio'] + label small,
input[type='checkbox'] + label small {
  color: #919191;
  display: block;
}
input[type='radio'] + label small strong,
input[type='checkbox'] + label small strong {
  color: red;
  display: block;
}

/*-----------  Oultine Blocks  -----------*/
.field-list .multiselect-wrapper .item-block,
.editor-wrapper .step-wrapper .radio-blocks-wrapper .radio-block {
  opacity: 0.75;
  border: 1px solid $inputBorderColor;
  color: #666666;
  line-height: 120%;
  position: relative;
  min-height: 2.75rem;
  margin-top: 0.375rem;
  padding: 0.5em 0.67em;
  border-radius: 5px;
  border-color: rgba(145, 145, 145, 0.5);
  justify-content: space-between;
  align-items: center;
  display: flex;
  transition: all 0.15s ease-out 0s;
}
.field-list .multiselect-wrapper .item-block:hover,
.editor-wrapper .step-wrapper .radio-blocks-wrapper .radio-block:hover {
  opacity: 1;
  cursor: pointer;
  border-color: rgba(102, 102, 102, 0.75);
}
.field-list .multiselect-wrapper .item-block i,
.editor-wrapper .step-wrapper .radio-blocks-wrapper .radio-block i {
  color: #54d388;
  line-height: 80%;
  font-size: 1.25rem;
  text-align: center;
  font-style: normal;
  font-weight: lighter;
  margin-right: 0.5rem;
  flex: 0 0 2rem;
}
.field-list .multiselect-wrapper .item-block > div,
.editor-wrapper .step-wrapper .radio-blocks-wrapper .radio-block > div {
  font-size: 0.8rem;
  flex: 1 1 auto;
}

/*-----------  Buttons  -----------*/
.editor-wrapper .button.button--link {
  color: #e8562a;
  border-color: white;
  background-color: transparent;
}
.editor-wrapper .button.button--link:hover {
  background-color: transparent;
}

/*-----------  Inverted Alternative  -----------*/
.goal-interstitial .goal-wrapper .goal-block .button {
  color: white;
  border-color: white;
  background-color: #e8562a;
}
.goal-interstitial .goal-wrapper .goal-block .button:hover {
  background-color: #d24217;
}

/*-----------  Solid Button  -----------*/
#contact-list-delete .button.delete,
.payment-confirmation-modal .package-block.elite .button,
.payment-confirmation-modal .package-block.premium .button,
.upgrade-account-modal .package-block.elite .button,
.upgrade-account-modal .package-block.premium .button {
  color: white;
  font-size: 0.95em;
  border-color: #e8562a;
  padding-top: 0.67em;
  padding-bottom: 0.67em;
  background-color: #e8562a;
}
#contact-list-delete .button.delete:hover,
.payment-confirmation-modal .package-block.elite .button:hover,
.payment-confirmation-modal .package-block.premium .button:hover,
.upgrade-account-modal .package-block.elite .button:hover,
.upgrade-account-modal .package-block.premium .button:hover {
  border-color: #e04718;
  background-color: #e04718;
}

/*-----------  Green Alternative  -----------*/
.reveal-wrapper .reveal-block .button,
.modal-wrapper .modal-block .button,
.modal-wrapper .block-small .button,
.editor-wrapper .button,
.editor-wrapper .step-wrapper .step-arrow,
.alternate-account-prompt-modal .thirds input.button,
.payment-account-modal footer .button.submit,
.new-credit-card-modal footer .button.submit,
.new-stripe-modal footer .button.submit {
  color: white;
  font-size: 0.95em;
  padding-top: 0.67em;
  padding-bottom: 0.67em;
  background-color: #27ab9e;
  border: 0 solid transparent;
}
.reveal-wrapper .reveal-block .button:hover,
.modal-wrapper .modal-block .button:hover,
.modal-wrapper .block-small .button:hover,
.editor-wrapper .button:hover,
.editor-wrapper .step-wrapper .step-arrow:hover,
.alternate-account-prompt-modal .thirds input.button:hover,
.payment-account-modal footer .button.submit:hover,
.new-credit-card-modal footer .button.submit:hover,
.new-stripe-modal footer .button.submit:hover {
  background-color: #196d64;
}

/*-----------  Raised Green Alternative  -----------*/
/*-----------  Blue Alternative  -----------*/
.payment-account-modal .payment-modal .payment-wrapper.elite .button,
.payment-account-modal .payment-modal .payment-wrapper.premium .button {
  color: white;
  font-size: 0.95em;
  padding-top: 0.67em;
  padding-bottom: 0.67em;
  background-color: #45b4d6;
  border: 0 solid transparent;
}
.payment-account-modal .payment-modal .payment-wrapper.elite .button:hover,
.payment-account-modal .payment-modal .payment-wrapper.premium .button:hover {
  background-color: #258aa9;
}

/*-----------  Gray Alternative  -----------*/
.modal-wrapper .modal-block .button.cancel,
.modal-wrapper .block-small .button.cancel,
.editor-wrapper .cancel-button,
.goal-interstitial footer .reveal-block form .cancel,
.alternate-account-prompt-modal .thirds input.button[disabled] {
  color: white;
  font-size: 0.95em;
  padding-top: 0.67em;
  padding-bottom: 0.67em;
  background-color: #c6c6c6;
  border: 0 solid transparent;
}
.modal-wrapper .modal-block .button.cancel:hover,
.modal-wrapper .block-small .button.cancel:hover,
.editor-wrapper .cancel-button:hover,
.goal-interstitial footer .reveal-block form .cancel:hover,
.alternate-account-prompt-modal .thirds input.button[disabled]:hover {
  background-color: #a0a0a0;
}

/*-----------  Dark Gray Alternative  -----------*/
.editor-wrapper .button[disabled] {
  color: white;
  font-size: 0.95em;
  padding-top: 0.67em;
  padding-bottom: 0.67em;
  background-color: #919191;
  border: 0 solid transparent;
}
.editor-wrapper .button[disabled]:hover {
  background-color: #6b6b6b;
}

/*-----------  Gray Inverted Alternative  -----------*/
.cancel-plan {
  color: #a5a5a5;
  text-decoration: underline;
  font-size: 0.9em;
}

.hint {
  font-size: 12px;
  padding: 8px;
  color: #c3c3c3;
}

label,
.label {
  font-size: 0.95em;
  font-weight: 600;
}

.temporary-block {
  padding: 2.25em;
  color: #919191;
  margin-left: auto;
  margin-right: auto;
  max-width: 44em;
  border-radius: 5px;
  text-align: center !important;
  border: 1px dashed $inputBorderColor;

  p {
    padding-left: 5em;
    padding-right: 5em;
  }
}

#hb-popup-container p {
  margin: 0;
}

@import './assets/styles/common';

.popup-type-icon {
  width: 32px;
}
