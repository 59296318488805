@import '../assets/styles/variables';

.button {
  color: white;
  outline: none;
  cursor: pointer;
  padding: 0.65em 1.25em;
  border-radius: 3px;
  border: 2px solid #27ab9e;
  background-color: #27ab9e;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.15s ease-out 0s;
  font-size: 0.85em;
  font-family: 'Montserrat', serif;

  &.full-width {
    width: 100%;
  }

  &.apply {
    margin-left: 103px;
  }

  &[disabled] {
    cursor: not-allowed;
  }

  i {
    line-height: 100%;
    vertical-align: middle;
    margin: 0 0.67em 0 -0.33em;
  }

  &-green {
    color: white;
    border: 1px solid #27ab9e;
    background-color: #27ab9e;

    &:hover {
      background-color: darken(#27ab9e, 15%);
      border-color: darken(#27ab9e, 15%);
    }
  }

  &-gradiant {
    color: $black;
    background: linear-gradient(to right, rgb(244, 171, 149), rgb(232, 86, 42));

    &:hover {
      background-color: darken(#e8562a, 15%);
      border-color: darken(#e8562a, 15%);
    }
  }

  &-red {
    color: white;
    border: 1px solid #e8562a;
    background-color: #e8562a;

    &:hover {
      background-color: darken(#e8562a, 15%);
      border-color: darken(#e8562a, 15%);
    }
  }

  &-white {
    border: 1px solid white;
    color: $grayDarker;
    background-color: white;
  }

  &-transparent-blue {
    border: 1px solid #4c39df;
    color: #4c39df;
    background-color: transparent;
    box-shadow: none;
  }

  &-gray-button,
  &-gray {
    color: $white;
    background-color: $grayLight;
    border: 1px solid transparent;

    &:hover {
      background-color: darken($grayLight, 15%);
    }
  }

  &-darkgray-button,
  &-darkgray {
    color: $white;
    padding-top: 0.67em;
    padding-bottom: 0.67em;
    background-color: $gray;
    border: 1px solid transparent;

    &:hover {
      background-color: darken($gray, 15%);
    }
  }

  &-gray-inverted-button {
    color: $grayLight;
    border-color: $grayLight;

    &:hover {
      color: $grayDark;
      border-color: darken($grayLight, 15%);
    }
  }

  &-blue-button,
  &-blue {
    color: $white;
    padding-top: 0.67em;
    padding-bottom: 0.67em;
    background-color: $blue;
    border: 0 solid transparent;

    &:hover {
      background-color: darken($blue, 15%);
    }
  }

  &-raised-green-button {
    color: $white;
    font-weight: $fontWeightBold;
    font-size: 0.95em;
    padding-top: 0.67em;
    padding-bottom: 0.67em;
    background-color: $greenDark;
    border: 0 solid transparent;
    border-bottom: 3px solid $greenShadow;

    &:hover {
      background-color: lighten($greenDark, 5%);
      border-bottom: 3px solid lighten($greenShadow, 5%);
    }
  }
}
