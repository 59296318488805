@import 'variables';

.sidebar {
  display: flex;
  min-height: calc(100% - 60px);
  background-color: #FAF9F9;
  position: relative;
  flex-direction: column;

  &-nav-link {
    height: 100%;
    width: $sidebarWidth;
    position: absolute;
    z-index: 1;
    // top: 63px;
    left: 0;
    bottom: 0;
    background-color: $grayLightest;
    // overflow-x: hidden;

    ul {
      margin-top: 0px;
      padding-left: 0;
      list-style: none;
      text-align: left;

      li {
        position: relative;
        padding: 1rem 1.33rem 0.95rem;

        a {
          text-decoration: none;
          width: 100%;
          color: $gray;

          i {
            width: 1.75em;
            font-size: 1.33em;
            display: -moz-inline-stack;
            display: inline-block;
            vertical-align: middle;
          }

          span {
            font-size: 14px;
            display: -moz-inline-stack;
            display: inline-block;
          }
        }

        &.active {
          a {
            color: $white !important;
            font-weight: 700;
          }
          // background-color: white;
          background: linear-gradient(to right, $orangeLightest, $red);
          // border-right: 1px solid $orangeLightest;
        }

        :hover {
          color: $red;
        }

        .badge {
          position: absolute;
          right: 16px;
          top: 50%;
          margin-top: -8px;
          height: 16px;
          background: linear-gradient(to right, #39c5df, #513add);
          color: white;
          font-size: 10px;
          line-height: 16px;
          font-weight: bold;
          padding: 0 8px;
          border-radius: 8px;
        }
      }

      li:hover {
        color: $red;
      }
    }
  }

  &-content {
    background: white;
    flex: 1;
    padding: 2.5em 3em 5em;
    margin-left: 14em;
    min-height: 100vh;
  }
}

.new-popup-wrapper {
  border-top: 1px solid $inputBorderColor;
  text-align: center;
  padding: 20px 0;
  display: block;

  a {
    display: grid;
    padding: 5px 15px;
    font-weight: 700;
  }
}

.subscribers-tooltip {
  border-top: 1px solid $inputBorderColor;
  text-align: center;
  padding-top: 20px;
  margin-top: 20px;
  width: 100%;
  position: absolute;

  button {
    color: #919191;
    background: transparent;
    border: 1px solid #919191;
    font-weight: bold;
    text-transform: none;

    &:before {
      content: '';
      position: relative;
      width: 18px;
      height: 18px;
      background: url('../icons/subscriberspush_icon_white.svg') no-repeat left
        center;
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.5em;
    }

    &:hover {
      color: #e8562a;
      border: 1px solid #e8562a;
      background-color: transparent;

      &:before {
        content: '';
        position: relative;
        width: 18px;
        height: 18px;
        background: url('../icons/subscriberspush_icon.svg') no-repeat left
          center;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  &-content {
    display: none;
    position: absolute;
    border: 3px #6a6467 solid;
    border-radius: 5px;

    img {
      width: 280px;
      cursor: help;
      max-width: fit-content;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 370px;
      right: 100%;
      margin-top: -5px;
      border-width: 15px;
      border-style: solid;
      border-color: transparent #6a6467 transparent transparent;
    }
  }

  &:hover &-content {
    display: block;
    animation: slide-grow-up 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) both;
    top: -350px;
    left: 100%;
  }
}

.demo-request {
  text-align: center;
  padding-top: 20px;
  margin-top: 20px;
  position: relative;
  width: 100%;

  button {
    color: #e8562a;
    background: transparent;
    border: 1px solid #e8562a;
    font-weight: bold;
    text-transform: none;

    &:before {
      content: '';
      position: relative;
      width: 20px;
      height: 20px;
      background: url('../images/hb-logo.png') #e8562a no-repeat left center;
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.5em;
    }

    &:hover {
      color: #919191;
      border: 1px solid #919191;
      background-color: transparent;
    }
  }
}
