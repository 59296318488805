@import './variables';
@import './mixins';

/*-----------  Site Installation  -----------*/

.installation-page {
  max-width: $maxWidth;
  margin: 0 auto;
  padding: 2rem;

  .install-header {
    text-align: center;
    margin-bottom: 3rem;

    h1 {
      font-size: 2.5rem;
      margin-bottom: 1rem;
      color: #333;
    }

    .subtitle {
      font-size: 1.1rem;
      color: #666;
      max-width: 600px;
      margin: 0 auto 2rem;
      line-height: 1.6;
    }
  }

  .section-title {
    font-size: 1.2rem;
    color: #444;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }

  .reveal-block {
    padding: 1.5rem;

    .title {
      font-size: 1.1rem;
      font-weight: 600;
      color: #333;
      margin: 1.5rem 0 1rem;
    }

    p {
      color: #666;
      line-height: 1.6;
    }

    pre {
      background: #f8f9fa;
      padding: 1rem;
      border-radius: 8px;
      margin: 1rem 0;
      font-size: 0.9rem;
    }

    .button-green {
      background: #4caf50;
      color: white;
      padding: 0.75rem 2rem;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      font-weight: 600;
      transition: all 0.3s ease;

      &:hover {
        background: #43a047;
        box-shadow: 0 2px 8px rgba(76, 175, 80, 0.3);
      }
    }
  }
}

.clipboard-btn {
  color: white;
  font-size: 0.95em;
}
