@import './_variables.scss';

.DateRange {
  margin-right: 10px;
  .MuiSelect-root {
    padding-left: 5px;
  }
}

.CustomDateRange {
  margin-right: 0;

  .MuiFormControl-root {
    margin-right: 5px;
  }

  & .MuiOutlinedInput-input {
    padding: 11.5px 10px;
  }
}

.SummaryToolbox {
  height: 80px;
}

.onboarding-section {
  margin: 20px 0;

  .accordion-item {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    background: white;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .accordion-header {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    gap: 15px;

    .status-icon {
      background: #e8f5f0;
      color: $red;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h2 {
      flex: 1;
      margin: 0;
      font-size: 16px;
      color: #333;
    }

    .time {
      color: #666;
      font-size: 14px;
    }

    .arrow {
      transition: transform 0.3s ease;
      font-size: 12px;
      color: #666;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .accordion-content {
    padding: 20px;

    .content-grid {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 30px;
      align-items: center;
    }

    .left-content {
      h3 {
        font-size: 24px;
        margin: 0 0 10px;
      }

      p {
        color: #666;
        margin-bottom: 20px;
      }
    }

    .get-started-btn {
      background: #ff5c35;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background: darken(#ff5c35, 5%);
      }
    }

    .video-container {
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}

.welcome-message {
  padding: 24px 0;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;

  h1 {
    font-size: 32px;
    margin-bottom: 16px;
    color: #333;
    font-weight: 600;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    color: #666;
    margin: 0;
  }
}
