.tooltip {
  background-color: 'black';
  color: 'white';
  padding: 8px;
  &-title {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  &-video {
    width: 230px;
    height: 200px;
    margin-bottom: 8px;
  }
  &-message {
    font-size: 12px;
    margin-bottom: 8px;
  }
  &-read-more {
    border: 1px solid #ffffff;
    border-radius: 4px;
    background-color: #7a7a7a69;
    font-size: 12px;
    &:hover {
      background-color: transparent;
      top: -2px;
    }
  }
}
